import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';

export const ContentHeaderHome = ({children}) => (

    <div className="row">
        <div className="col-xs-12 mbottom-default">
    <div className="row content-header-home flexbox mbottom-default-sm">
        <div className="col-xs-12 col-sm-4">
            <div className="padding-default bg-tertiary">
            <div className="row">
                <div className="col-xs-12">
                <div id="homepage-content-header-content" className="row">
                <div className="col-xs-12 col-ms-6 col-sm-12">
                    <div className="padding-none">
                        {children}
                    </div>
                </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        
        <div className="col-xs-12 col-sm-8 image-container bg-tertiary">
            {/* todo: Check next year where the title of the image is to make sure it is visible. This year it was in the top right of the image. */}
            <StaticImage src="../images/fears24.jpg" loading="eager" className="bg-image-container" imgStyle={{objectPosition: 'right top'}} id="homepage-image"/>
        </div>
        <div className="display" id="homepage-title">
            <h2 className="main-title m-n">Be fierce. Show your research.</h2>
            <h1>FEARS 2024</h1>
            <div className="standalone-link">The 22nd Faculty of Engineering and Architecture Research Symposium on November 27th. <span>
            {/* <a href="https://evan.ugent.be/a/fears2023/" target="_blank" rel="noopener noreferrer" activeClassName="active"><strong>Submit your abstract now</strong></a> */}
            {/* <a href="https://evan.ugent.be/r/fears2023/" target="_blank" rel="noopener noreferrer" activeClassName="active"><strong>Register now!</strong></a> */}
            </span></div>
        </div>
        
    </div>
    </div>
    </div>
)

export default ContentHeaderHome
